import Vue from "vue";

Vue.use(require('vue-cookies'))

const that = Vue.prototype
const state = Vue.observable({
    exchangeBuyPrice: 0,
    exchangeSellPrice: 0,
    changeOrder:0,
    coins: false,
    theme:'light',
    simple:false,
    lang:'fa',
    orderChange: 0,
    Excoin:'',
    // token: localStorage.token || Vue.$cookies.get('--abcd14589'),
    token:'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NTQ1Nzk5MjEsImV4cCI6MTY4NjExNTkyMX0.BLN9uIsQiemluwICs_DdOD5-Hzr_8Cv98vu7e0LTIpc',
    loading: false,
    gotten: false,
    loop: true,
    reload: 0,
    progress: 0,
    network:'TRC20',
    towStepAuthenticationType: '',
    walletAction:'deposit',
    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        authentication:'',
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },

    buyAmount: 0,
    buyPrice: 0,
    sellAmount: 0,
    sellPrice: 0
})
that.state = state
export default state
