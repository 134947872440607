import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./library/globalFunctions";
import "./library/state";
import "./library/globalConstants";
import "./library/georgianToJalali";
import "./library/axios";
import './style/style.scss'
import './style/Fonts/iran-sans.scss'
import "./library/reuseableFunction";
import VueCookies from "vue-cookies"
import VSquircle from "vue-squircle";
import InlineSvg from 'vue-inline-svg';
import VueApexCharts from 'vue-apexcharts'
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import 'animate.css'

Vue.prototype.moment = moment
Vue.use(VueApexCharts)
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.component('multi-select', Multiselect)
Vue.use(PerfectScrollbar,{
  options:{
    // swipeEasing: false,
    wheelPropagation: false,
  }
});
import "sweetalert2/dist/sweetalert2.min.css";
// import "./style/SweetAlertStyle.scss";
import VueSweetalert2 from "vue-sweetalert2";

import Carousel3d from 'vue-carousel-3d';

Vue.use(Carousel3d);

Vue.component('apex-chart', VueApexCharts)
Vue.config.productionTip = false
Vue.use(VueCookies);
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI);
Vue.use(VSquircle);
Vue.use(VueSweetalert2)
Vue.component('inline-svg', InlineSvg);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

