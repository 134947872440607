import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import routes from "@/router/routes";
import routes from './routes'
import Controller from './controller'
Vue.use(VueRouter)





const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
Controller(router);
export default router
