import Vue from "vue";
const that = Vue.prototype;
import {auth,disabledRoutes,otp,forLogin,unAuthorUser} from './guards'
export default function(router){
return router.beforeEach(async (to, from, next) => {
        that.state.loading = true;
        if (!that.state.coins) {
            await that.$getCoins();
        }
        if (that.state.token) {
            if (!that.state.gotten) {
                that.state.loading = true;
                await that.$getuserInfo();
                that.state.loading = true;
                await that.$getFees();
            }

            // if(that.state.token && that.state.userInfo.authorized){
            //     next()
            // }
            // if(that.state.token && that.state.userInfo.authorized){
            //     next({name: "Account"});
            // }
            that.state.loading = true;
            if (that.state.userInfo.supervisor && disabledRoutes.includes(to.name)) {
                that.$error(
                    "",
                    "با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است"
                );
                next(from);
            }
            if (from.name === "Blog") {
                await window.open(to.fullPath, "_self");
            } else if (otp.includes(to.name) || auth.includes(to.name)) {
                next({name: "HomePage"});
            } else if (to.name === "Trade") {
                if (!to.params.tradeFrom || !to.params.tradeTo) {
                    next("/profile/trade/TOMAN/BITCOIN");
                } else {
                    try {
                        that.state.loading = true;
                        await that.$axios(
                            `/markets/${to.params.tradeFrom}_${to.params.tradeTo}`
                        );
                        next();
                    } catch (e) {
                        if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                            if (otp.includes(from.name)) {
                                next({name: "HomePage"});
                            } else {
                                await that.$error("بازار غیرفعال است");
                                next(from);
                            }
                        }
                    }
                }
            } else {
                if (that.state.userInfo.authorized) {
                    next();
                } else {
                    if (otp.includes(from.name) && to.name !== "Account") {
                        next({name: "Account"});
                    } else if (unAuthorUser.includes(to.name)) {
                        that.state.loading = false;
                        const res = await that.$error(
                            "عدم دسترسی",
                            "برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود",
                            "error",
                            "حساب کاربری"
                        );
                        if (res.isConfirmed) {
                            next("/profile/account");
                        } else {
                            next(from);
                        }
                    } else next();
                }
            }
        } else {
            if (
                (otp.includes(to.name) && !Vue.$cookies.isKey("username")) ||
                forLogin.includes(to.name)
            )
                next({name: "Login"});
            else {
                next();
            }
        }
        that.state.loading = false;
    });
}
