

export default  [
    {
        path: '/404',
        name: '404',
        component: () => import('../views/public/404.vue')
    },
    {
        path: '*',
        redirect:'/404'
    },
    {
        path: '/',
        name: 'main',
        component: () => import('../layouts/PublicLayout'),
        children:[

            {
                path: '/',
                name: 'home',
                component: ()=>import('../views/public/HomeView.vue')
            },
            {
                path: '/about',
                name: 'about',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/public/AboutView.vue')
            },
            {
                path:'/contact',
                name:'contact',
                component:()=>import('../views/public/ContactView.vue')
            },
            {
                path:'/market',
                name:'market',
                component:()=>import('../views/public/MarketView.vue')
            },
            {
                path: '/coin/:coin',
                name:'coin',
                component:()=>import('../views/public/Coin.vue'),
                props:true

            },
            {
                path:'/wages',
                name:'wages',
                component:()=>import('../views/public/WagesView.vue')
            },
            {
                path:'/rules',
                name:'rules',
                component:()=>import('../views/public/RulesView.vue')
            },
            {
                path:'/faq',
                name:'faq',
                component:()=>import('../views/public/FAQView.vue')
            },

            {
                path:'/authorization',
                name:'Authorization',
                component:()=>import('../views/panel/Authorization.vue')
            },
            {
                path:'/OTC',
                name:'OTC',
                component:()=>import('../views/panel/OTC.vue')
            },



            {
                path:'/auth',
                name:'auth',
                component:()=>import('../views/auth/Authentication.vue'),
                children:[
                    {
                        path:'login',
                        name:'login',
                        component:()=>import('../views/auth/Login.vue')
                    },
                    {
                        path:'register',
                        name:'register',
                        component:()=>import('../views/auth/Register.vue')
                    },
                    {
                        path:'forgot',
                        name:'forgot',
                        component:()=>import('../views/auth/Forgot.vue')

                    },
                    {
                        path:'reset',
                        name:'reset',
                        component:()=>import('../views/auth/Reset.vue')
                    }
                    ,
                    {
                        path:'otp',
                        name:'otp',
                        component:()=>import('../views/auth/OTP.vue')
                    }
                ]
            },
        ]
    },
    {
        path:'/panel',
        name:'panel',
        component:()=>import('../layouts/PanelLayout.vue'),
        children: [
            {
                path:'/panel',
                name:'panel',
                component:()=>import('../views/panel/Dashboard.vue')
            },
            {
                path:'/support',
                name:'support',
                component:()=>import('../views/panel/Level.vue')
            },
            {
              path:'/history',
              redirect:'/history/orders'
            },
            {
                path:'/history',
                name:'history',
                component:()=>import('../views/panel/History.vue'),
                children:[
                    {
                        path:'orders',
                        name:'orderHistory',
                        component:()=>import('../components/HistoryOrderPage')
                    },
                    {
                        path:'trades',
                        name:'tradeHistory',
                        component:()=>import('../components/HistoryTradePage')
                    },
                    {
                        path:'crypto',
                        name:'cryptoHistory',
                        component:()=>import('../components/HistoryCryptoPage')
                    },
                    {
                        path:'rial',
                        name:'rialHistory',
                        component:()=>import('../components/HistoryRialPage')
                    },
                    {
                        path:'login',
                        name:'historyLogin',
                        component:()=>import('../components/HistoryLoginPage')
                    }
                ]
            },

            {
                path:'/notifications',
                name:'notifications',
                component:()=>import('../views/panel/Notification.vue')

            },

            {
                path:'/settings',
                name:'settings',
                component:()=>import('../views/panel/Settings.vue')
            },
            {
                path:'/wallet',
                name:'wallet',
                component:()=>import('../views/panel/Wallet.vue'),
                children:[
                    {
                        path:'/inventory',
                        name:'inventory',
                        component:()=>import('../components/WalletsInventory.vue')

                    },
                    {
                        params:true,
                        path:'/transaction',
                        name:'transaction',
                        component:()=>import('../components/WalletTransaction.vue')
                    }
                ]
            },
            {
                path:'/profile',
                name:'profile',
                component:()=>import('../views/Profile')
            }

        ]
    },
    {path:'/p2p',
    name:'p2p',
        component:()=>import('../layouts/TradeLayout'),
        children: [
            {
                props:true,
                path:'/p2p/:tradeTo/:tradeFrom',
                name:'p2p',
                component:()=>import('../views/panel/P2P.vue')
            },
            {
                path:'/p2p',
                name:'p2p',
                redirect:'/p2p/TOMAN/BITCOIN'
            },
        ]
    }



];

