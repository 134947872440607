 export const disabledRoutes = [];
/*
 * this is the guard for routes that shouldn't
 * load without Login
 * */
 export const forLogin = [
    "dashboard",
    "Account",
    "otc",
    "trade",
    "history",
    "wallet",
    "invite",
    "settings",
    "profile",
];
//Users that did not authorized must not access these pages
 export const unAuthorUser = [
    "history",
    "wallet",
    "dashboard",
    "wallet",
    "walletEx",
    "otc",
    // "trade",
];
 export const otp = [
    // "SendPhone", "SendMail"
];
 export const auth = [
    // 'Login',
    // "SignUp",
    // "Forget",
];

