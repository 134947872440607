<template>
  <div id="app" :class="state.theme==='light'? 'light' :'dark'" :key="state.theme">
    <loading v-if="state.loading"/>
    <router-view/>
  </div>
</template>
<script>
import Loading from "@/components/PublicLoading";
export default {
  components: {Loading}
}
</script>

<style lang="scss">

</style>
